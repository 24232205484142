<style lang="less" scoped>
	.content {
		background: #F5F5F5;

		.head {
			padding: 36px 0;
			text-align: center;
			font-size: 44px;
			width: 1301px;
			margin: 0 auto;
			position: relative;

			.skip {
				font-size: 22px;
				background: #D8D8D8;
				padding: 11px 55px;
				position: absolute;
				right: 0;
			}
		}

		.author_basicInformation {
			width: 1301px;
			margin: 0 auto;

			.title {
				padding: 24px 0;
				font-size: 28px;
				text-align: left;
				font-weight:bold;
				.right {
					float: right;
					color: #ffffff;
					background-color: #932224;
					padding: 7px 15px;
					cursor: pointer;
				}
			}

			.basicInx_box {
				background-color: #ffffff;
				text-align: left;
				padding: 40px 0;
				margin-bottom: 20px;

				/deep/.el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap>.el-form-item__label:before,
				/deep/.el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before {
					content: '' !important;
					color: #F56C6C;
					margin-right: 0 !important;
				}

				/deep/.el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap>.el-form-item__label:before,
				/deep/.el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:after {
					content: '*' !important;
					color: #F56C6C !important;
					margin-right: 0.25rem;
				}

				.close {
					text-align: right;
					font-size: 30px;
					cursor: pointer;
					span {
						font-size: 30px;
						background: #FFFFFF;
						border-radius: 50%;
						padding: 0px 10px;
						text-align: right;
						position: relative;
						top: -55px;
						// color: #FFFFFF;
						right: -15px;
					}
				}
			}
		}
	}

	/deep/ .el-input__inner {
		height: 58px;
		font-size: 22px;
	}

	/deep/ .el-form-item__label {
		font-size: 22px;
		line-height: 58px;
	}

	/deep/ .el-radio__label {
		font-size: 22px;
	}

	/deep/ .el-radio__input {
		margin-bottom: px(5);

		&.is-checked {
			.el-radio__inner {
				background-color: #932224;
				border-color: #932224;
			}
		}

		.el-radio__inner {
			&:hover {
				border-color: #932224;
			}
		}
	}

	/deep/ .el-radio__input.is-checked+.el-radio__label {
		color: #606266;
	}

	/deep/ .el-form-item__content {
		text-align: initial;
		line-height: 58px;
	}

	.width200 {
		width: 200px;
		float: left;
		padding-right: 5px
	}

	.width200:nth-child(1) {
		margin-right: 10px;
	}

	.width410 {
		width: 410px;
	}

	.two {
		line-height: 60px;
	}

	.img {
		width: 130px;
		height: 165px;
	}

	.foot {
		padding: 94px 0;
		display: flex;
		justify-content: center;
		align-items: center;

		.btn {
			width: 334px;
			height: 66px;
			padding-right: 20px;
		}
	}

	.up_img {
		width: 102px;
		height: 102px;
		border: 1px solid #979797;
		text-align: center;

		p {
			line-height: 0;
		}
		img {
			width: 40px;
			height: 40px;
			margin-top: 15px;
		}
	}

	.up_photo {
		width: 102px;
		height: 102px;
		border: 1px solid #979797;
		object-fit: cover;
	}

	.el-input__inner:focus {
		transition: all 1.4s linear 0s;
		border-color: #932224 !important;
	}

	.el_Option {
		font-size: 22px !important;
	}

	.el-select-dropdown__item.selected {
		color: #932224 !important;
	}

	.el-select-dropdown__item {}

	.el-select-dropdown__item.hover,
	.el-select-dropdown__item:hover {
		color: #932224 !important;
	}

	.el-input__inner {

		font-size: 20px;
		height: 58px !important;
		border-radius: 0;
	}

	.el-form-item__label {
		font-size: 22px;
		text-align: right;
		line-height: 58px;
		font-weight: 420;
	}

	.el-input {
		height: 55px;
	}


	/deep/.el-input__inner:focus {
		transition: all 1.4s linear 0s;
		border-color: #932224 !important;
	}

	/deep/.el-input__prefix .el-input__icon {
		display: none;
	}

	/deep/.el-input--prefix .el-input__inner {
		padding-left: 20px;
	}

	.el_Option {
		font-size: 22px !important;
	}

	/deep/.el-select-dropdown__item.selected {
		color: #932224 !important;
	}

	/deep/.el-select-dropdown__item.hover,
	/deep/.el-select-dropdown__item:hover {
		color: #932224 !important;
	}

	/deep/.el-input__inner {
		font-size: 20px;
		height: 58px !important;
		border-radius: 0;
	}

	/deep/.el-form-item__label {
		font-size: 22px !important;
		text-align: right;
		line-height: 58px;
		font-weight: 420;
		position: relative;
	}

	/deep/.el-input {
		height: 55px;
	}

	/deep/.el-date-table td.today span {
		color: #932224;
		font-weight: 700;
	}

	.el-year-table td.today .cell /deep/.el-date-table td.current:not(.disabled) span {
		background-color: #932224 !important;
	}

	/deep/.el-year-table td .cell:hover {
		color: #932224 !important;
	}

	/deep/ .affiliatedUnit {
		.el-form-item__label {
			line-height: 30px;
		}
	}
</style>
<template>
	<div class="content">
		<div class="head">
			{{index==0?$t('addAuthor.informationAuthor1'):index==1?$t('addAuthor.informationAuthor2'):index==2?$t('addAuthor.informationAuthor3'):index==3?$t('addAuthor.informationAuthor4'):index==4?$t('addAuthor.informationAuthor5'):""}}
			<span v-if="skip<3" class="skip" @click="skipList" style="cursor: pointer;">{{$t('newText.msg9')}}</span>
		</div>
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="9.11458vw" :inline="true"
			class="demo-ruleForm">
			<div class="author_basicInformation">
				<div class="title">
					<span>{{$t('addAuthor.basicInformation')}}</span>
				</div>
				<div class="basicInx_box">
					<div>
						<el-form-item :label="$t('addAuthor.personalPhoto')" prop="image">
							<div class="up_img" v-if="ruleForm.image==''">
								<img src="@/assets/phone.png" />
								<p>{{$t('personalCenter.photo')}}</p>
							</div>
							<div class="icon_image up_photo" v-else>
								<img :src="ruleForm.image" />
							</div>
							<div v-if="skip<=5&&skip>0">
								<uploading @success="success" :name="'image'"></uploading>
							</div>
						</el-form-item>
					</div>
					<el-form-item :label="$t('addAuthor.name')" prop="en_name">
						<div class="width410">
							<el-input v-model="ruleForm.en_name" :disabled="skip>5||skip<0" :placeholder="$t('addAuthor.name')"></el-input>
						</div>
					</el-form-item>
					<el-form-item :label="$t('addAuthor.sex')" prop="en_sex">
						<el-select v-model="ruleForm.en_sex" :disabled="skip>5||skip<0" :placeholder="$t('addAuthor.hintsex')" class="width410">
							<el-option v-for="item in sexList" :key="item" :label="item" :value="item">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item :label="$t('addAuthor.nationality')" prop="en_nationality">
						<el-select v-model="ruleForm.en_nationality" :disabled="skip>5||skip<0" :placeholder="$t('addAuthor.hintnationality')"
							class="width410">
							<el-option v-for="(item,index) in countryList" :key="index" :label="item.name"
								:value="item.name"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item :label="$t('addAuthor.yearBirth')" prop="birth">
						<el-date-picker :picker-options="pickerBeginDateBefore" value-format="yyyy-MM-dd HH:mm:ss" :disabled="skip>5||skip<0" :editable="false" v-model="ruleForm.birth"
							type="year" class="width410" :placeholder="$t('addAuthor.hintYearBirth')"></el-date-picker>
					</el-form-item>
					<el-form-item :label="$t('addAuthor.idNumber')" prop="id_card"
						v-if="ruleForm.en_nationality=='中国 (China)'">
						<div class="width410">
							<el-input v-model="ruleForm.id_card" :disabled="skip>5||skip<0" :maxlength="18"
								:placeholder="$t('addAuthor.hintIdNumber')"></el-input>
						</div>
					</el-form-item>
					<el-form-item :label="$t('addAuthor.phone')" prop="mobile">
						<div class="width410">
							<el-input type="text" v-model="ruleForm.mobile" :disabled="skip>5||skip<0" :maxlength="20"
								:placeholder="$t('addAuthor.hintPhone')"></el-input>
						</div>
					</el-form-item>
					<el-form-item :label="$t('addAuthor.city')" v-if="ruleForm.en_nationality=='中国 (China)'" prop="en_city">
						<el-cascader size="large" :options="options" :disabled="skip>5||skip<0" :placeholder="$t('addAuthor.hintcity')"
							class="width410" v-model="ruleForm.en_city" :props="{value:'label'}">
						</el-cascader>
					</el-form-item>
					<el-form-item :label="$t('addAuthor.ress')" prop="en_address">
						<div class="width410">
							<el-input v-model="ruleForm.en_address" :disabled="skip>5||skip<0" :placeholder="$t('addAuthor.hintress')"></el-input>
						</div>
					</el-form-item>
					<el-form-item :label="$t('addAuthor.affiliatedUnit')" prop="en_company" class="affiliatedUnit">
						<div class="width410">
							<el-input v-model="ruleForm.en_company" :disabled="skip>5||skip<0" :placeholder="$t('addAuthor.hintaffiliatedUnit')">
							</el-input>
						</div>
					</el-form-item>
					<el-form-item :label="$t('addAuthor.position')" prop="en_position">
						<div class="width410">
							<el-input v-model="ruleForm.en_position" :disabled="skip>5||skip<0" :placeholder="$t('addAuthor.hintposition')">
							</el-input>
						</div>
					</el-form-item>
				</div>
			</div>
			<div class="author_basicInformation">
				<div class="title">
					<span>{{$t('addAuthor.studyExperience')}}</span>
					<span class="right" @click="addaward(1)"  v-if="skip<=5&&skip>0">+</span>
				</div>
				<div class="basicInx_box" v-for="(item,index) in ruleForm.school" :key="index">
					<div class="close" v-if="index!=0">
						<span @click="closeRuleForm(1,index)"  v-if="skip<=5&&skip>0">×</span>
					</div>
					<el-form-item :label="$t('addAuthor.schoolName')" :prop="`school[${index}].en_school_name`"
						:rules="{required: true,message: $t('newText.msg10'),trigger: 'blur'}">
						<el-input v-model="item.en_school_name" :disabled="skip>5||skip<0" :placeholder="$t('addAuthor.hintschoolName')"
							class="width410">
						</el-input>
					</el-form-item>
					<el-form-item :label="$t('addAuthor.major')" :prop="`school[${index}].en_post`" :rules="{
						required: true,
						message: $t('newText.msg11'),
						trigger: 'blur'
					}">
						<el-input v-model="item.en_post" :disabled="skip>5||skip<0" :placeholder="$t('addAuthor.hintmajor')" class="width410">
						</el-input>
					</el-form-item>
					<el-form-item :label="$t('addAuthor.start_times')" :prop="`school[${index}].start_time`" :rules="{
						required: true,
						message: $t('newText.msg12'),
						trigger: 'blur'
					}">
						<el-date-picker v-model="item.start_time" :disabled="skip>5||skip<0" type="month" :editable="false"
							:placeholder="$t('addAuthor.start_time')" class="width410" value-format="timestamp">
						</el-date-picker>
					</el-form-item>
					<el-form-item :label="$t('addAuthor.end_times')" :prop="`school[${index}].end_time`" :rules="{
						required: true,
						message: $t('newText.msg13'),
						trigger: 'blur'
					}">
						<el-date-picker v-model="item.end_time" type="month" :editable="false"
							:placeholder="$t('addAuthor.end_time')" :disabled="skip>5||skip<0" class="width410" value-format="timestamp">
						</el-date-picker>
					</el-form-item>
				</div>
			</div>
			<div class="author_basicInformation">
				<div class="title">
					<span>{{$t('addAuthor.occupationalHistory')}}</span>
					<span class="right" @click="addaward(2)"  v-if="skip<=5&&skip>0">+</span>
				</div>
				<div class="basicInx_box" v-for="(item,index) in ruleForm.working" :key="index">
					<div class="close" v-if="index!=0">
						<span @click="closeRuleForm(2,index)"  v-if="skip<=5&&skip>0">×</span>
					</div>
					<el-form-item :label="$t('addAuthor.organization')" prop="name">
						<el-input v-model="item.en_company_name" :disabled="skip>5||skip<0" :placeholder="$t('addAuthor.hintorganization')"
							class="width410">
						</el-input>
					</el-form-item>
					<el-form-item :label="$t('addAuthor.duty')" prop="name">
						<el-input v-model="item.en_post" :disabled="skip>5||skip<0" :placeholder="$t('addAuthor.hintduty')" class="width410">
						</el-input>
					</el-form-item>
					<el-form-item :label="$t('addAuthor.start_times')" prop="name">
						<el-date-picker v-model="item.start_time" :disabled="skip>5||skip<0"  type="month" :editable="false"
							:placeholder="$t('addAuthor.start_time')" class="width410">
						</el-date-picker>
					</el-form-item>
					<el-form-item :label="$t('addAuthor.end_times')" prop="name">
						<el-date-picker v-model="item.end_time" :disabled="skip>5||skip<0"  type="month" :editable="false"
							:placeholder="$t('addAuthor.end_time')" class="width410" value-format="timestamp">
						</el-date-picker>
					</el-form-item>
					<el-form-item :label="$t('addAuthor.organizationphone')" prop="name">
						<el-input v-model="item.company_mobile" :disabled="skip>5||skip<0"  :placeholder="$t('addAuthor.hintorganizationphone')"
							class="width410" value-format="timestamp">
						</el-input>
					</el-form-item>
				</div>
			</div>
			<div class="author_basicInformation">
				<div class="title">
					<span>{{$t('addAuthor.Awards')}}</span>
					<span class="right" @click="addaward" v-if="skip<=5&&skip>0">+</span>
				</div>
				<div class="basicInx_box" v-for="(item,index) in ruleForm.award" :key="index">
					<div class="close" v-if="index!=0">
						<span @click="closeRuleForm(3,index)" v-if="skip<=5&&skip>0">×</span>
					</div>
					<el-form-item :label="$t('addAuthor.contestName')" prop="q">
						<el-input v-model="item.en_court" :disabled="skip>5||skip<0" :placeholder="$t('addAuthor.hintcontestName')"
							class="width410">
						</el-input>
					</el-form-item>
					<el-form-item :label="$t('addAuthor.awards')" prop="w">
						<el-input v-model="item.en_prize" :disabled="skip>5||skip<0"  :placeholder="$t('addAuthor.hintawards')" class="width410">
						</el-input>
					</el-form-item>
					<el-form-item :label="$t('addAuthor.awardsTime')" prop="e">
						<el-date-picker v-model="item.reward_time" :disabled="skip>5||skip<0"  type="month" :editable="false"
							:placeholder="$t('addAuthor.hintawardsTime')" class="width410">
						</el-date-picker>
					</el-form-item>
				</div>
			</div>
			<div class="foot">
				<!-- <div class="btn">
					<elbutton @btnTap="btnTap"></elbutton>
				</div> -->
				<div class="btn" v-show="index!=0">
					<elbutton @btnTap="backtrack" :text="$t('addAuthor.footBth2')"></elbutton>
				</div>
				<div class="btn" v-show="index+1!=$route.query.number">
					<elbutton @btnTap="btnTap" :text="$t('addAuthor.footBth')"></elbutton>
				</div>
				<div class="btn" v-show="skip<=5&&skip>0">
					<elbutton @btnTap="save" :text="$t('addAuthor.footBth3')"></elbutton>
				</div>
				<div class="btn" v-show="index+1==$route.query.number&&skip<=5&&skip>0">
					<elbutton @btnTap="submit" :text="$t('addAuthor.footBth1')"></elbutton>
				</div>
			</div>
		</el-form>
	</div>
</template>

<script>
	import elbutton from '@/components/ele/elButton.vue'
	import formInput from '@/components/ele/formInput.vue'
	import uploading from '@/components/ele/uploading.vue'
	import {
		regionData,
	} from 'element-china-area-data'
	export default {
		components: {
			formInput,
			elbutton,
			uploading
		},
		props: {
			index: [Number],
			ruleForm: [{
				type: [Object],
				default: {}
			}],
		},
		watch: {
			ruleForm(value) {
				console.log(value)
			}
		},
		data() {
			return {
				pickerBeginDateBefore: {
					disabledDate(time) {
						return time.getTime() > Date.now();
					}
				},
				sexList: this.$t('newText.msg14'),
				options: regionData,
				countryList: this.$util.countryList,
				skip: "",
				addshow: true,
				rules: {
					image: [{
						required: true,
						message:  this.$t('newText.msg15'),
						trigger: 'change'
					}],
					en_name: [{
						required: true,
						message:  this.$t('newText.msg16'),
						trigger: 'blur'
					}],
					en_sex: [{
						required: true,
						message:  this.$t('newText.msg17'),
						trigger: 'change'
					}],
					en_nationality: [{
						required: true,
						message:  this.$t('newText.msg18'),
						trigger: 'change'
					}],
					birth: [{
						required: true,
						message:  this.$t('newText.msg19'),
						trigger: 'blur'
					}],
					id_card: [{
						message:  this.$t('newText.msg20'),
						trigger: 'blur'
					}],
					mobile: [{
						required: true,
						message:  this.$t('newText.msg21'),
						trigger: 'blur'
					}],
					// en_city: [{
					// 	message:  this.$t('newText.msg22'),
					// 	trigger: 'change'
					// }],
					en_address: [{
						required: true,
						message:  this.$t('newText.msg23'),
						trigger: 'blur'
					}],
					en_company: [{
						required: true,
						message:  this.$t('newText.msg24'),
						trigger: 'blur'
					}],
					en_position: [{
						required: true,
						message:  this.$t('newText.msg25'),
						trigger: 'blur'
					}],
				}

			}
		},
		mounted() {
			// console.log(this.options)
			this.options.forEach((item, index) => {
				if (item.label == "海外(overseas)") {
					this.addshow = false
				}
			})
			if (this.addshow) {
				this.options.push({
					children: [{
						children: [{
							label: "海外(overseas)",
							value: "22222",
						}],
						label: "海外(overseas)",
						value: "22222",
					}],
					label: "海外(overseas)",
					value: "11111",
				})
			}

			this.entriesDetail()
		},
		methods: {
			skipList() {
				sessionStorage.setItem('headIndex', 1)
				this.$router.push({
					name: 'PersonalCenter',
				})
			},
			async entriesDetail() {
				try {
					let data = {
						id: this.$route.query.id
					}
					let _this = this;
					let res = await this.$axios.post('entries/detail', data);
					if (res.code == 1) {
						let data = res.data;
						_this.skip = data.en_check_state
					}

				} catch (e) {

					//TODO handle the exception
				}
			},
			save() {
				this.$emit('saveAuthor', this.ruleForm, this.index)
			},
			backtrack() {
				this.$emit('backtrack', this.ruleForm, this.index)
			},
			async submit(val) {
				this.$refs.ruleForm.validate(async valid => {
					if (valid) {
						this.$emit('submit', this.ruleForm, this.index)
					} else {
						window.scrollTo({
							'top': 0,
							'behavior': 'smooth'
						})
						console.log('error submit!!')
						return false
					}
				})
			},
			btnTap() {
				this.$refs.ruleForm.validate(valid => {
					if (valid) {
						window.scrollTo({
							'top': 0,
							'behavior': 'smooth'
						})
						this.$emit('authorArr', this.ruleForm, this.index)
					} else {
						window.scrollTo({
							'top': 0,
							'behavior': 'smooth'
						})
						console.log('error submit!!')
						return false
					}
				})
			},
			closeRuleForm(type, index) {
				if (type == 1) {
					this.ruleForm.school.splice(index, 1)
				}
				if (type == 2) {
					this.ruleForm.working.splice(index, 1)
				}
				if (type == 3) {
					this.ruleForm.award.splice(index, 1)
				}
			},
			success(a, b) {
				this.ruleForm.image = a
				this.$refs.ruleForm.validate(async valid => {
					if (valid) {
					} else {
						
					}
				})

			},
			addaward(type) {
				if (type == 1) {
					this.ruleForm.school.push({
						en_school_name: "",
						en_post: "",
						start_time: "",
						end_time: ""
					})
				} else if (type == 2) {
					this.ruleForm.working.push({
						en_company_name: "",
						en_describe: "",
						start_time: "",
						end_time: "",
					})
				} else {
					this.ruleForm.award.push({
						en_court: "",
						en_prize: "",
						reward_time: "",
					})
				}
			}
		}
	}
</script>
